<template>
  <div class="peoLineManagement">
    <!-- <button class="btn" @click="clockin()" v-if="usersta == 0">上班打卡</button>
	<button class="btn" @click="asyncClockoutafterwork()" v-if="usersta == 1">下班打卡</button> -->
    <el-dialog
      title="温馨提示"

      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span v-if="usersta == 0">下班打卡成功</span>
      <span v-if="usersta == 1">上班打卡成功</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- <span>此按钮用于调度员上下班打卡， 接收来单短信！</span> -->
    <el-main>
      <el-table
        ref="multipleTable"
        :data="List"
        tooltip-effect="dark"
        style="width: 100%"
        highlight-current-row
        stripe
        border
        :header-cell-style="rowClass"
      >
        <el-table-column
          prop="onlineTime"
          label="上架时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="logoutTime"
          label="下架时间"
          align="center"
        ></el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template slot-scope="scope">
            {{ scope.row.status == 1 ? "上线" : "下线" }}
          </template>
        </el-table-column>
        <el-table-column prop="userTel" label="手机号" align="center"></el-table-column>
        <el-table-column label="详情" align="center" min-width="280px">
          <template slot-scope="scope">
            <el-button type="primary" @click="clockin(scope.row)" v-if="scope.row.status == 0"
              >上班打卡</el-button
            >
            <el-button type="primary" @click="asyncClockoutafterwork(scope.row)" v-if="scope.row.status == 1"
              >下班打卡</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handlePageNumChange"
        :current-page="paging.pageNo"
        :page-sizes="[5, 10, 20, 50]"
        :page-size="paging.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-main>
  </div>
</template>

<script>
export default {
  name: 'peoLineManagement',
  components: {},
  data () {
    return {
      List: [],
      dialogVisible: false,
      direction: 'rtl',
      usersta: '',
      total: 0,
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      } // 分页表单
    }
  },
  created () {
    // this.draweri()
    this.getList()
  },
  methods: {
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },

    // 查询调度员打卡状态
    async draweri () {
      const { data: res } = await this.$http.post(
        '/userManageServer/yardman/selectInfoBackstageyardmanClockInStatus',
        {
          userId: sessionStorage.getItem('userId')
        }
      )
      console.log(res)
      if (res) {
        this.usersta = res.data
      }
    },
    // 上班打卡
    async clockin (item) {
      const { data: res } = await this.$http.post(
        '/userManageServer/yardman/updateInfoBackstageyardmanOnlineStatus',
        {
          status: 1,
          userId: item.userId
        }
      )
      if (res) {
        // this.dialogVisible = true
        this.getList()
      }
    },
    // 下班打卡
    async asyncClockoutafterwork (item) {
      const { data: res } = await this.$http.post(
        '/userManageServer/yardman/updateInfoBackstageyardmanLogoutStatus',
        {
          status: 0,
          userId: item.userId
        }
      )
      if (res) {
        this.getList()
        // this.dialogVisible = true
      }
    },
    async getList () {
      const { data: res } = await this.$http.post(
        '/userManageServer/yardman/selectContactList',
        {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo
        }
      )
      this.List = res.data.records
      this.total = res.data.total
      //   console.log(res.data.total, 'res.data.total')
    }, // 切换每页显示数量时触发
    async handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      await this.getList()
    },
    // 切换页码时触发
    async handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      await this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.peoLineManagement {
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
  display: flex;
  // align-items: center;
  justify-content: center;
  //隐藏滚动条
  ::-webkit-scrollbar {
    // display: none; /* Chrome Safari */
  }
  .btn {
    width: 300px;
    height: 60px;
    background: none;
    border: 4px solid;
    color: #3498db;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 16px;
    position: relative;
    margin-top: 20%;
  }
  .btn::before,
  .btn::after {
    content: "";
    position: absolute;
    width: 14px;
    height: 4px;
    background: #f1f1f1;
    transform: skewX(50deg);
    transition: 0.4s linear;
  }
  .btn::before {
    top: -4px;
    left: 10%;
  }
  .btn::after {
    bottom: -4px;
    right: 10%;
  }
  .btn:hover::before {
    left: 80%;
  }
  .btn:hover::after {
    right: 80%;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
}
</style>
